import React, { useState } from "react"

export default function HeroRadioBadgeComponent() {
  const [selectedPrimary, setSelectedPrimary] = useState("huey01")
  const [selectedSecondary, setSelectedSecondary] = useState("huey02")

  const handleSelectedPrimary = e => {
    setSelectedPrimary(e.target.value)
  }
  const handleSelectedSecondary = e => {
    setSelectedSecondary(e.target.value)
  }
  return (
    <div className="flex flex-col gap-10 rounded-md sm:flex-row">
      <div>
        {/* <!-- Primary Radio Buttons --> */}
        <fieldset onChange={handleSelectedPrimary}>
          <legend className="mb-6 text-slate-500">Primary Radio Group:</legend>
          <div className="relative flex items-center mb-6">
            <input
              className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="radio"
              value="huey01"
              id="huey01"
              name="huey01"
              checked={selectedPrimary === "huey01"}
            />
            <label
              className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="huey01"
            >
              Huey
            </label>
            <svg
              className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-white peer-checked:scale-100 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="title-1 description-1"
              role="graphics-symbol"
            >
              <title id="title-1h">Circle Shape</title>
              <desc id="description-1h">
                Circle shape to indicate whether the radio input is checked or
                not.
              </desc>
              <circle cx="8" cy="8" r="4" />
            </svg>
          </div>
          <div className="relative flex items-center mb-6">
            <input
              className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="radio"
              value="dewey01"
              id="dewey01"
              name="dewey01"
              checked={selectedPrimary === "dewey01"}
            />
            <label
              className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="dewey01"
            >
              Dewey
            </label>
            <svg
              className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-white peer-checked:scale-100 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="title-2h description-2h"
              role="graphics-symbol"
            >
              <title id="title-2h">Circle Shape</title>
              <desc id="description-2h">
                Circle shape to indicate whether the radio input is checked or
                not.
              </desc>
              <circle cx="8" cy="8" r="4" />
            </svg>
          </div>
          <div className="relative flex items-center mb-6">
            <input
              className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="radio"
              value="louie01"
              id="louie01"
              name="louie01"
              checked={selectedPrimary === "louie01"}
            />
            <label
              className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="louie01"
            >
              Louie
            </label>
            <svg
              className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-white peer-checked:scale-100 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="title-3h description-3h"
            >
              <title id="title-3h">Circle Shape</title>
              <desc id="description-3h">
                Circle shape to indicate whether the radio input is checked or
                not.
              </desc>
              <circle cx="8" cy="8" r="4" />
            </svg>
          </div>
        </fieldset>
        {/* <!-- End Primary Radio Buttons --> */}
      </div>

      <div>
        {/* <!-- Secondary Radio Buttons --> */}
        <fieldset onChange={handleSelectedSecondary}>
          <legend className="mb-6 text-slate-500">
            Secondary Radio Group:
          </legend>
          <div className="relative flex items-center mb-6">
            <input
              className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-emerald-500 checked:bg-emerald-200 checked:hover:border-emerald-600 checked:hover:bg-emerald-300 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="radio"
              value="huey02"
              id="huey02"
              name="huey02"
              checked={selectedSecondary === "huey02"}
            />
            <label
              className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="huey02"
            >
              Huey
            </label>
            <svg
              className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-emerald-500 peer-checked:scale-100 peer-checked:opacity-100 peer-hover:fill-emerald-600 peer-focus:fill-emerald-700 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="title-01hl description-01hl"
              role="graphics-symbol"
            >
              <title id="title-01hl">Circle Shape</title>
              <desc id="description-01hl">
                Circle shape to indicate whether the radio input is checked or
                not.
              </desc>
              <circle cx="8" cy="8" r="4" />
            </svg>
          </div>
          <div className="relative flex items-center mb-6">
            <input
              className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-emerald-500 checked:bg-emerald-200 checked:hover:border-emerald-600 checked:hover:bg-emerald-300 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="radio"
              value="dewey02"
              id="dewey02"
              name="dewey02"
              checked={selectedSecondary === "dewey02"}
            />
            <label
              className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="dewey02"
            >
              Dewey
            </label>
            <svg
              className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-emerald-500 peer-checked:scale-100 peer-checked:opacity-100 peer-hover:fill-emerald-600 peer-focus:fill-emerald-700 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="title-02hl description-02hl"
              role="graphics-symbol"
            >
              <title id="title-02hl">Circle Shape</title>
              <desc id="description-02hl">
                Circle shape to indicate whether the radio input is checked or
                not.
              </desc>
              <circle cx="8" cy="8" r="4" />
            </svg>
          </div>
          <div className="relative flex items-center mb-6">
            <input
              className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-emerald-500 checked:bg-emerald-200 checked:hover:border-emerald-600 checked:hover:bg-emerald-300 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="radio"
              value="louie02"
              id="louie02"
              name="louie02"
              checked={selectedSecondary === "louie02"}
            />
            <label
              className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="louie02"
            >
              Louie
            </label>
            <svg
              className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-emerald-500 peer-checked:scale-100 peer-checked:opacity-100 peer-hover:fill-emerald-600 peer-focus:fill-emerald-700 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="title-03hl description-03hl"
              role="graphics-symbol"
            >
              <title id="title-03hl">Circle Shape</title>
              <desc id="description-03hl">
                Circle shape to indicate whether the radio input is checked or
                not.
              </desc>
              <circle cx="8" cy="8" r="4" />
            </svg>
          </div>
        </fieldset>
        {/* <!-- End Secondary Radio Buttons --> */}
      </div>
    </div>
  )
}
