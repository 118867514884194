import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import RadioButtonsPrimaryPreview from "../../../../library/components/form-elements/radio/react/_preview/primary"
import RadioButtonsSecondaryPreview from "../../../../library/components/form-elements/radio/react/_preview/secondary"

import HeroRadioBadgeComponent from "../../../../library/components/form-elements/radio/react/_preview/heroRadioComponent"
// *** Radio Primary *** //
const RadioButtonsPrimaryJsx = require("!!raw-loader!../../../../library/components/form-elements/radio/react/primary.jsx")
const RadioButtonsPrimaryHTML = require("!!raw-loader!../../../../library/components/form-elements/radio/html/primary.html")

// *** Radio Secondary *** //
const RadioButtonsSecondaryJsx = require("!!raw-loader!../../../../library/components/form-elements/radio/react/secondary.jsx")
const RadioButtonsSecondaryHTML = require("!!raw-loader!../../../../library/components/form-elements/radio/html/secondary.html")

export default function FormElementRadioGroupsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "RadioButtonsPrimary",
      title: "Primary radio buttons",
      active_tab: 1,
    },
    {
      component_name: "RadioButtonsSecondary",
      title: "Secondary",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Radio Groups - WindUI Component Library"
        ogtitle="Tailwind CSS Radio Groups - WindUI Component Library"
        description="Radio Group components are generally used in groups, describing a set of related options, only one can be selected at a time. Built with Tailwind CSS by WindUI."
        ogdescription="Radio Group components are generally used in groups, describing a set of related options, only one can be selected at a time. Built with Tailwind CSS by WindUI."
        url="components/form-elements/radio-groups/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Radio Groups, Radio Groups, Radio Group Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Radio Groups</h1>
          <p>
            Radio buttons are generally used in groups - collections, describing
            a set of related options. Only one radio button in a given group can
            be selected at the same time.
          </p>

          {/* Radio Buttons Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <HeroRadioBadgeComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Primary Radio Buttons Section*/}
          <h3 id="RadioButtonsPrimary">Primary Radio Buttons</h3>

          <PreviewBlock
            id="RadioButtonsPrimary"
            HtmlComponent={RadioButtonsPrimaryHTML.default}
            JsxComponent={RadioButtonsPrimaryJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <RadioButtonsPrimaryPreview
                RadioButtonsPrimary={
                  activeTabs[0].active_tab === 1
                    ? RadioButtonsPrimaryHTML.default
                    : RadioButtonsPrimaryJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Secondary Radio Buttons Section*/}
          <h3 id="RadioButtonsSecondary">Secondary Radio Buttons</h3>

          <PreviewBlock
            id="RadioButtonsSecondary"
            HtmlComponent={RadioButtonsSecondaryHTML.default}
            JsxComponent={RadioButtonsSecondaryJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <RadioButtonsSecondaryPreview
                RadioButtonsSecondary={
                  activeTabs[1].active_tab === 1
                    ? RadioButtonsSecondaryHTML.default
                    : RadioButtonsSecondaryJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            All radio buttons should be wrapped in a{" "}
            <code>&lt;fieldset&gt;</code> element that should have a
            <code>&lt;legend&gt;</code> tag to provide a description for the
            grouping.
          </p>

          <p>Use the radio buttons to:</p>
          <ul>
            <li>Select a single option from a list</li>
            <li>Expose all available options</li>
          </ul>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Radio buttons come in two basic styles (<strong>primary</strong> and{" "}
            <strong>secondary</strong>). Both have 1rem (16px) height and width
            and 0.5rem spacing between the button and the label.
          </p>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            The <code>&lt;fieldset&gt;</code> surrounds the entire grouping of
            checkboxes or radio buttons. The <code>&lt;legend&gt;</code>
            provides a description of the grouping.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
